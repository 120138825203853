@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #181818;
  color: #fff;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #181818;
  filter: blur(4px);
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  color: white;
}

input::placeholder {
  color: white;
}

* {
  margin: 0;
}

@keyframes ImagefadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ImagefadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.image-fade-in {
  animation: ImagefadeIn 3s ease-in-out;
}

.image-fade-out {
  animation: ImagefadeOut 3s ease-in-out;
}

[class^='masonry'][data-column='1'] {
  grid-template-columns: repeat(1, 1fr);
}

[class^='masonry'][data-column='2'] {
  grid-template-columns: repeat(2, 1fr);
}

[class^='masonry'][data-column='3'] {
  grid-template-columns: repeat(3, 1fr);
}

[class^='masonry'][data-column='4'] {
  grid-template-columns: repeat(4, 1fr);
}

[class^='masonry'][data-column='5'] {
  grid-template-columns: repeat(5, 1fr);
}

[class^='masonry']>div[data-row='1'] {
  grid-row: auto / span 1;
}

[class^='masonry']>div[data-row='2'] {
  grid-row: auto / span 2;
}

[class^='masonry']>div[data-row='3'] {
  grid-row: auto / span 3;
}

[class^='masonry']>div[data-row='4'] {
  grid-row: auto / span 4;
}

[class^='masonry']>div[data-row='5'] {
  grid-row: auto / span 5;
}

.preview-wrap .ant-modal .ant-modal-content {
  padding: 0;
  border-radius: 20px;
}

.my-selelct .ant-select-selector {
  background: transparent !important;
}

.my-selelct .ant-select-selector span {
  color: white;
  text-align: left;
}

.my-selelct .ant-select-arrow .anticon-search {
  color: white;
}

.my-option {
  background: rgba(0, 0, 0, 0.6) !important;
  color: white;
  border-radius: 0px !important;
}

.my-option .ant-select-item {
  border-radius: 0px !important;
}

.my-option .ant-select-item-option-content {
  background: transparent !important;
  color: white;
  cursor: pointer;
  border-radius: 0px !important;
}

.my-option .ant-select-item-option-content:hover {
  /* border-bottom: 1px dashed white; */
  font-weight: bold;
}

.my-custom-dropdown-class {
  background: transparent !important;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}

/* SkeletonScreen.css */
.skeleton {
  animation: skeleton-loading 2s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f2f2f2;
  }

  50% {
    background-color: #d3d0d0;
  }

  100% {
    background-color: #f2f2f2;
  }
}

/* .masonry-flex-wrap {
  gap: 0px !important;
} */

/* .masonry-flex-wrap .masonry-flex-wrap-column {
  gap: 0px !important;
} */

.tags {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tags.expanded {
  white-space: normal;
}

.sort-popover .ant-popover-content {
  border-radius: 10px;
}

.sort-popover .ant-popover-content .ant-popover-inner {
  background: #374151;
}

.sort-popover .ant-popover-arrow::before {
  background: #374151;
}

/* .media-preview-modal {
  width: 80vw;
} */
.ant-image-preview-progress {
  display: none;
}

html,
body,
#root {
  height: 100%
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}